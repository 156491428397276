<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="mb-4">
        <v-divider></v-divider>
        <v-img
          :src="require('../assets/Pozzy_Banner.png')"
          class="my-3"
          height="100%"
          width="100%"
        />
      </v-col>

      <v-col class="mb-4">
        <br>
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Pozzyvibes
        </h1>
        <p>
          From weddings to birthdays, holiday parties, community events, and more, we've got your back.
        </p>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br>
    <v-container>
      <v-responsive align="center" :aspect-ratio="3" >
        <embed
            src="https://www.youtube.com/embed/l9O0WQbs-Uk?si=tZcnVHqLR2oAztnK"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="HowtoUsePozzyvibes360PhotoBooth"
            width="80%"
            height="100%"
        >
      </v-responsive>
    </v-container>
    <v-container>
      <v-sheet
      >
        <v-img
            src="../assets/events/Winner-2024-Daytona's-Best-DJ-Tiffany-Pozzyvibes.png"
            class="white--text align-end"
            aspect-ratio="1.7"
            contain
        ></v-img>
        <div align="center">
        <h2>Voted Daytona's Best DJ for Weddings & Events!<br>
          Thank You for Your Votes!<br>
          *2024 Winner! - Best DJ - Event/Wedding*<br>
          *2024 Finalist - Best Photographer/Videographer - 360 Photo Booth*<br>
          *2023 Finalist - Best DJ - Event/Wedding*<br></h2>
          <a href="https://yourchoiceawards.com/daytona/">View 2024 Award Winners </a>from Daytona Community Choice Awards<br> 
         </div>
      </v-sheet>
    </v-container>
    <v-container>
      <v-row class="text-center" align="center" justify="center">
        <v-col cols="auto">
          <embed
              src="https://open.spotify.com/embed/playlist/46WWrmhP1OhaQX4cDBG6l4?utm_source=generator"
              width="300"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media">
        </v-col>
        <v-col cols="auto">
          <!--<v-responsive align="center" :aspect-ratio="3" >--> 
           <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPozzyvibes&tabs=timeline&width=300&height=380&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width="300"
                height="380"
                frameborder="0"
                style="border:none;overflow:hidden"
                allowtransparency="true"
                allow="encrypted-media"
            ></iframe>
         <!--</v-responsive>-->
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
    }),
  }
</script>
